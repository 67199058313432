/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { resolveImage, resolveAboveCta, resolveAddress } from "@dataResolvers";
import { Dealers as PageTemplate } from "@pageTemplates";
import { getCountryNames } from "@utils";
import QueryContainer from "@templates/QueryContainer";

export const query = graphql`
  query FindADealerQuery($siteId: [Craft_QueryArgument]) {
    craft {
      entry(section: "findADealer", siteId: $siteId) {
        title
        ... on Craft_findADealer_findADealer_Entry {
          #Hero
          heroTitle
          entrySubtitle
          #Header + Intro
          heading
          intro
          # Filter Copy
          chooseCountry
          chooseRegion
          prefilteredCopy
          #Above Footer CTAs
          aboveFooterCtaImageBlocks {
            ...AboveFooterFragment
          }
          #Meta
          metaDescription
          metaFeaturedImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
      entries(section: "dealers", siteId: $siteId) {
        ... on Craft_dealers_dealers_Entry {
          title
          latitude
          longitude
          address {
            name
            street
            street2
            postalCode
            city
            state
            country
            latitude
            longitude
          }
          website
          telephone
          fax
        }
      }
    }
  }
`;

const dataResolver = ({ entry, entries }) => {
  if (!entry) return null;
  const {
    title,
    heading,
    intro,
    chooseCountry,
    chooseRegion,
    prefilteredCopy,
    aboveFooterCtaImageBlocks,
    metaFeaturedImage,
    metaDescription,
  } = entry;

  return {
    title,
    heading,
    intro,
    dealers: entries?.map(dealer => {
      const { state: region } = dealer.address;
      return {
        ...dealer,
        address: resolveAddress(dealer.address),
        longitude: Number(dealer?.longitude),
        latitude: Number(dealer?.latitude),
        country: getCountryNames[dealer?.address?.country],
        region,
      };
    }),
    filterCopy: {
      chooseCountry,
      chooseRegion,
      prefilteredCopy,
    },
    countries: entries
      ?.map(dealer => getCountryNames[dealer?.address?.country])
      .sort()
      .filter((item, pos, self) => {
        // eslint-disable-next-line eqeqeq
        return self.indexOf(item) == pos;
      }),
    geojson: {
      type: "FeatureCollection",
      features: entries
        ?.map((dealer, i) => {
          return {
            type: "Feature",
            properties: {
              id: i,
              title: dealer?.title,
              address: resolveAddress(dealer.address),
              telephone: dealer?.telephone,
              website: dealer?.website,
            },
            geometry: {
              type: "Point",
              coordinates: [
                Number(dealer?.longitude),
                Number(dealer?.latitude),
              ],
            },
          };
        })
        ?.filter(_d => {
          // Remove any dealer that don't have coordinates
          return (
            _d?.geometry?.coordinates?.[0] && _d?.geometry?.coordinates?.[1]
          );
        }),
    },
    cards: resolveAboveCta(aboveFooterCtaImageBlocks),
    meta: {
      title,
      metaFeaturedImage: resolveImage(metaFeaturedImage),
      metaDescription,
    },
  };
};

const Page = ({ data, pageContext }) => (
  <QueryContainer
    data={data}
    pageContext={pageContext}
    PageTemplate={PageTemplate}
    dataResolver={dataResolver}
  />
);

export default Page;
